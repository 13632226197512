// components/MapNavigation.js

import React from 'react';
import { AppBar, Tabs, Tab, Typography, Toolbar, Button } from '@mui/material';

const MapNavigation = ({ mapName, username, handleTabChange, handleLogout, resetMap }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {mapName} Interactive Map
        </Typography>
        <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
          {username ? `Hello, ${username}` : 'Not logged in'}
        </Typography>
        <Button color="inherit" onClick={handleLogout}>Logout</Button>
      </Toolbar>
      <Tabs onChange={handleTabChange} indicatorColor="secondary" textColor="inherit" centered>
        <Tab label="Home" onClick={() => handleTabChange(null, 0)} />
        <Tab label={`${mapName} Map`} onClick={resetMap} />
      </Tabs>
    </AppBar>
  );
};

export default MapNavigation;

