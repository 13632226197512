// src/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Ensure this CSS file exists for styling

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://165.232.129.161:5000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Login successful:', data);
        // Save username to localStorage for session tracking
        localStorage.setItem('username', username);
        navigate('/home'); // Redirect to home page on successful login
      } else {
        setError(data.error || 'Failed to login. Please check your username and password.');
      }
    } catch (error) {
      setError('Unable to connect to server. Please try again later.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Log In</button>
        {error && <p className="error-message">{error}</p>}
        <p className="create-account-text">
          Don't have an account? <a href="/register">Create one</a>
        </p>
      </form>
    </div>
  );
}

export default Login;

