import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Tabs, Tab, Box, Typography, Toolbar, Button, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import mapImageUSA from './pics/USA.png';
import mapImageNevada from './pics/Nevada.png';
import mapImageLasVegas from './pics/theStrip.jpg';
import mapImageLasVegasDowntown from './pics/lasvegasdowntown.png';

const Home = () => {
  const navigate = useNavigate();
  const [mapImage, setMapImage] = useState(mapImageUSA);
  const [areas, setAreas] = useState([]);

  const initialAreas = [
    {
      name: "usaMap",
      coords: "110,148,118,111,170,125,155,197,152,197,149,198,148,204,147,204,142,196",
      alt: "Nevada"
    }
  ];

  const nevadaAreas = [
    {
      name: "nevadaMap",
      coords: "278,403,386,402,389,449,381,463,363,452,347,453,349,501,356,523,352,533,277,466",
      alt: "Las Vegas"
    }
  ];

  const lasVegasAreas = [
    
    {
      name: "lasVegasDowntown",
      coords: "308,30,308,88,391,88,391,30",
      alt: "Las Vegas Downtown"
    },
	{
      name: "lasVegas",
      coords: "226,392,226,392,226,393,226,393,226,394,225,394,225,395,225,396,224,396,224,396,224,397,223,397,222,397,222,398,221,398,221,398,220,398,219,398,219,398,218,398,218,397,217,397,216,397,216,396,216,396,215,396,215,395,215,394,214,394,214,393,214,393,214,392,214,391,214,391,214,390,215,390,215,389,215,388,216,388,216,388,216,387,217,387,218,387,218,386,219,386,219,386,220,386,221,386,221,386,222,386,222,387,223,387,224,387,224,388,224,388,225,388,225,389,225,390,226,390,226,391,226,391,226,392",
      alt: "The Strip"
    }
  ];

  const lasVegasDowntownAreas = [
    {
      name: "backToStrip",
      coords: "7,40,7,98,80,98,80,40",
      alt: "Back to the Strip"
    },
    {
      name: "plaza",
      coords: "136,164,128,162,124,159,123,153,126,148,135,147,140,151,140,158,136,163",
      alt: "Plaza"
    }
  ];

  const areaClickHandlers = {
    "usaMap": () => {
      console.log("Clicked on Nevada");
      updateMap(mapImageNevada, nevadaAreas);
    },
    "nevadaMap": () => {
      console.log("Clicked on Las Vegas");
      updateMap(mapImageLasVegas, lasVegasAreas);
    },
    "lasVegas": () => {
      console.log("Clicked on The Strip");
      navigate("/bellagio");
    },
    "lasVegasDowntown": () => {
      console.log("Clicked on Las Vegas Downtown");
      updateMap(mapImageLasVegasDowntown, lasVegasDowntownAreas);
    },
    "backToStrip": () => {
      console.log("Clicked on Back to the Strip");
      updateMap(mapImageLasVegas, lasVegasAreas);
    },
    "plaza": () => {
      console.log("Clicked on Plaza");
      navigate("/plaza");
    }
  };

  const updateMap = (newMapImage, newAreas) => {
    setMapImage(newMapImage);
    setAreas(newAreas);
  };

  useEffect(() => {
    setAreas(initialAreas);
  }, []);

  const handleAreaClick = (areaName) => {
    if (areaClickHandlers[areaName]) {
      areaClickHandlers[areaName]();
    }
  };

  const [tabValue, setTabValue] = useState(0);

  const [username, setUsername] = useState(localStorage.getItem('username') || 'Guest');

  useEffect(() => {
    const handleStorageChange = () => {
      setUsername(localStorage.getItem('username') || 'Guest');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabClick = (tabName) => {
    console.log(`You clicked the ${tabName} tab.`);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            {username ? `Hello, ${username}` : 'Not logged in'}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        centered
      >
        <Tab label="Home" onClick={() => handleTabClick('Home')} />
        <Tab label="History" onClick={() => handleTabClick('History')} />
        <Tab label="Definitions" onClick={() => handleTabClick('Definitions')} />
        <Tab label="Tips" onClick={() => handleTabClick('Tips')} />
        <Tab label="Blog" onClick={() => handleTabClick('Blog')} />
        <Tab label="Merch" onClick={() => handleTabClick('Merch')} />
        <Tab label="Maps" onClick={() => handleTabClick('Maps')} />
      </Tabs>
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Map of USA
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          {mapImage ? (
            <img src={mapImage} alt="Map" useMap={`#map`} />
          ) : (
            <p>Map image not found</p>
          )}
          <map name="map">
            {areas.map((area, index) => (
              <area
                key={index}
                shape="poly"
                coords={area.coords}
                alt={area.alt}
                onClick={() => handleAreaClick(area.name)}
              />
            ))}
          </map>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;

