import React from 'react';
import InteractiveMultiMap from './components/InteractiveMultiMap';
import mapImagePlazaLevel1 from './pics/PlazaLevel1.png';
import mapImagePlazaLevel2 from './pics/PlazaLevel2.png';
import mapImagePlazaLevel3 from './pics/PlazaLevel3.png';
import mapImagePlazaLevel1SubMap1 from './pics/PlazaLevel1SubMap1.png';

const plazaFloors = {
  level1: {
    mainImage: mapImagePlazaLevel1,
    areas: [
      { name: "level1NTE", vertices: "859,215,859,276,909,276,909,215", alt: "level1NTE", img: mapImagePlazaLevel2 },
      { name: "level1SU", vertices: "662,432,662,468,726,468,726,432", alt: "level1SU", img: mapImagePlazaLevel2 },
      { name: "level1STE", vertices: "162,251,162,330,200,330,200,251", alt: "level1STE", img: mapImagePlazaLevel3 }
    ],
    subsections: [
      { img: mapImagePlazaLevel1SubMap1, vertices: "1087,438,1086,458,1052,476,970,431,995,390,1072,435" }
    ]
  },
  level2: {
    mainImage: mapImagePlazaLevel2,
    areas: [
      { name: "level2SD", vertices: "14,296,14,322,81,322,81,296", alt: "level2SD", img: mapImagePlazaLevel1 },
      { name: "level2NTE", vertices: "219,3,219,79,290,79,290,3", alt: "level2NTE", img: mapImagePlazaLevel1 },
      { name: "level2SU", vertices: "17,263,17,289,82,289,82,263", alt: "level2SU", img: mapImagePlazaLevel3 }
    ],
    subsections: []
  },
  level3: {
    mainImage: mapImagePlazaLevel3,
    areas: [
      { name: "level3STE", vertices: "74,170,74,245,112,245,112,170", alt: "level3STE", img: mapImagePlazaLevel1 },
      { name: "level3SD", vertices: "590,314,590,329,633,329,633,314", alt: "level3SD", img: mapImagePlazaLevel2 }
    ],
    subsections: []
  }
};

const PlazaMulti = () => {
  return (
    <InteractiveMultiMap
      mapName="Plaza"
      mainImage={mapImagePlazaLevel1} 
      floors={plazaFloors}
    />
  );
};

export default PlazaMulti;

