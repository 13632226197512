// Bellagio.js

import React from 'react';
import InteractiveMap from './components/InteractiveMap';
import mapImageBellagio from './pics/bellagio.jpg';
import bellagioSection1 from './pics/BellagioSubSection/bellagioSubSection1.jpg';
import bellagioSection2 from './pics/BellagioSubSection/bellagioSubSection2.jpg';
import bellagioSection3 from './pics/BellagioSubSection/bellagioSubSection3.jpg';
import bellagioSection4 from './pics/BellagioSubSection/bellagioSubSection4.jpg';
import bellagioSection5 from './pics/BellagioSubSection/bellagioSubSection5.jpg';
import bellagioSection6 from './pics/BellagioSubSection/bellagioSubSection6.jpg';
import bellagioSection7 from './pics/BellagioSubSection/bellagioSubSection7.jpg';
import bellagioSection8 from './pics/BellagioSubSection/bellagioSubSection8.jpg';

const bellagioSections = [
  { img: bellagioSection1, vertices: "707,1100,703,934,712,922,714,911,891,910,890,932,844,984,843,996,798,1052,781,1055,736,1110,718,1110" },
  { img: bellagioSection2, vertices: "707,880,707,765,734,764,733,847,739,854,743,855,830,856,839,850,839,764,865,765,875,792,891,812,889,903,717,903,714,895,712,889,709,883,705,879" },
  { img: bellagioSection3, vertices: "864,744,799,745,798,554,805,546,810,539,812,532,812,526,810,523,929,422,932,424,940,424,945,424,948,425,948,486,891,535,891,698,875,716,872,722" },
  { img: bellagioSection4, vertices: "919,389,800,390,792,399,793,505,801,509,917,406,915,398" },
  { img: bellagioSection5, vertices: "622,473,766,520,776,507,786,505,788,505,788,397,780,388,669,389" },
  { img: bellagioSection6, vertices: "763,529,622,482,548,620,537,645,538,728,546,728,546,744,781,744,780,554,768,545,766,537" },
  { img: bellagioSection7, vertices: "613,765,684,764,683,861,674,857,661,855,642,862,641,839,613,837" },
  { img: bellagioSection8, vertices: "687,950,685,1100,680,1103,677,1106,676,1109,649,1111,599,997,599,955,626,955,634,949,644,958,661,961,674,958" }
];

const Bellagio = () => {
  return (
    <InteractiveMap
      mapName="Bellagio"
      mainImage={mapImageBellagio}
      submaps={bellagioSections}
    />
  );
}

export default Bellagio;

