// src/utils/mapUtils.js

export const parseVertices = (verticesStr) => {
  const points = verticesStr.split(',');
  return points.reduce((acc, value, index, array) => {
    if (index % 2 === 0) {
      acc.push({ x: parseInt(value, 10), y: parseInt(array[index + 1], 10) });
    }
    return acc;
  }, []);
};

export const calculateCentroid = (vertices) => {
  let area = 0;
  let cx = 0;
  let cy = 0;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const a = vertices[i].x * vertices[j].y - vertices[j].x * vertices[i].y;
    cx += (vertices[i].x + vertices[j].x) * a;
    cy += (vertices[i].y + vertices[j].y) * a;
    area += a;
  }
  area /= 2;
  cx /= (6 * area);
  cy /= (6 * area);
  return { x: Math.abs(cx), y: Math.abs(cy) };
};

