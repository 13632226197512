import React, { useState, useEffect, forwardRef } from 'react';
import './Tooltip.css';
import { fetchSlotByName, fetchSlotNames } from '../api';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';

const Tooltip = forwardRef(({ slot, tooltipPosition, handleSlotChange, saveSlot, deleteSlot }, ref) => {
  const [slotData, setSlotData] = useState(slot);
  const [slotNames, setSlotNames] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setSlotData(slot);
  }, [slot]);

  useEffect(() => {
    const fetchNames = async () => {
      try {
        const names = await fetchSlotNames();
        console.log('Fetched slot names:', names); // Log the fetched slot names
        setSlotNames(names);
      } catch (error) {
        console.error('Failed to fetch slot names:', error);
      }
    };
    fetchNames();
  }, []);

  const handleNameChange = async (selectedSlot) => {
    console.log('Selected slot:', selectedSlot); // Log the selected slot
    console.log('Selected slot .location_id:', selectedSlot.location_id);
	const updatedSlotData = { ...slotData, name: selectedSlot.name };
    setSlotData(updatedSlotData);

    try {
      const fetchedSlot = await fetchSlotByName(selectedSlot.name);
      if (fetchedSlot) {
        console.log('Fetched slot details by name:', fetchedSlot); // Log the fetched slot details
        setSlotData(prevData => ({
          ...prevData,
          ...fetchedSlot,
          location_id: prevData.location_id,
          x_coordinate: prevData.x_coordinate,
          y_coordinate: prevData.y_coordinate,
          submap_id: prevData.submap_id,
          date: prevData.date,
          software: prevData.software,
        }));
      }
    } catch (error) {
      console.error('Failed to fetch slot by name:', error);
    }
  };

  const handleSlotChangeWrapper = (e) => {
    const { name, value } = e.target;
    const updatedSlotData = { ...slotData, [name]: value };

    if (name === 'x' || name === 'y') {
      updatedSlotData[`x_coordinate`] = updatedSlotData.x;
      updatedSlotData[`y_coordinate`] = updatedSlotData.y;
    }

    setSlotData(updatedSlotData);
    console.log('Updated slot data:', updatedSlotData); // Log updated slot data
  };

  const filteredSlotNames = query === '' ? slotNames : slotNames.filter(slotName =>
    slotName.toLowerCase().includes(query.toLowerCase())
  );

  console.log('Slot names in combo box:', filteredSlotNames); // Log the slot names stored in the combo box

  return (
    <div
      className="tooltip"
      style={{
        position: 'absolute',
        left: `${tooltipPosition.x}px`,
        top: `${tooltipPosition.y}px`
      }}
      ref={ref}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const finalSlotData = {
            ...slotData,
            x_coordinate: slotData.x,
            y_coordinate: slotData.y,
          };
          console.log('Saving slot data:', finalSlotData); // Log the slotData before saving
          saveSlot(finalSlotData); // Send the updated slotData
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%'
        }}
      >
        <div className="combobox-container" style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
          <label style={{ width: '50%', textAlign: 'right', paddingRight: '10px' }}>Name:</label>
          <Combobox value={slotData.name} onChange={handleNameChange}>
            <ComboboxInput
              aria-label="Slot Name"
              displayValue={(name) => name}
              onChange={(event) => setQuery(event.target.value)}
              className="combobox-input"
            />
            <ComboboxOptions className="combobox-options">
              {filteredSlotNames.map((name, index) => (
                <ComboboxOption key={index} value={{ name }} className="combobox-option">
                  {name}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          </Combobox>
        </div>
        <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
          <label style={{ width: '50%', textAlign: 'right', paddingRight: '10px' }}>Location Description:</label>
          <input
            type="text"
            name="description"
            value={slotData?.description || ''}
            onChange={handleSlotChangeWrapper}
            style={{ width: '50%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
          <label style={{ width: '50%', textAlign: 'right', paddingRight: '10px' }}>Cost:</label>
          <input
            type="number"
            name="cost"
            value={slotData?.cost || 0}
            onChange={handleSlotChangeWrapper}
            style={{ width: '50%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
          <label style={{ width: '50%', textAlign: 'right', paddingRight: '10px' }}>Path:</label>
          <input
            type="text"
            name="path"
            value={slotData?.path || ''}
            onChange={handleSlotChangeWrapper}
            style={{ width: '50%' }}
          />
        </div>
        <div className="button-container">
          <button type="button" onClick={() => deleteSlot(slotData.id)}>Delete</button>
          <button type="submit">Save</button>
        </div>
      </form>
    </div>
  );
});

export default Tooltip;


