// src/Dashboard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Tabs, Tab, Box, Typography, Toolbar, Button, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Import your images here
import mapImageUSA from './pics/USA.png';
import mapImageNevada from './pics/Nevada.png';
import mapImageLasVegas from './pics/theStrip.jpg';
import mapImageBellagio from './pics/bellagio.jpg';

function YourComponent() {
  const [tabValue, setTabValue] = useState(0); // Default tab index, 0 for the first tab
  const [mapImage, setMapImage] = useState(mapImageUSA);
  const [areas, setAreas] = useState([
    {
      name: "usaMap",
      coords: "110,148,118,111,170,125,155,197,152,197,149,198,148,204,147,204,142,196",
      alt: "Nevada",
      onClick: () => {
        setMapImage(mapImageNevada);
        setAreas([
          {
            name: "nevadaMap",
            coords: "278,403,387,403,388,448,382,461,376,462,366,453,359,452,348,456,346,462,348,472",
            alt: "Las Vegas",
            onClick: () => {
              setMapImage(mapImageLasVegas);
              setAreas([
                {
                  name: "lasVegas",
                  coords: "226,392,226,392,226,393,226,393,226,394,225,394,225,395,225,396,224,396,224,396,224,397,223,397,222,397,222,398,221,398,221,398,220,398,219,398,219,398,218,398,218,397,217,397,216,397,216,396,216,396,215,396,215,395,215,394,214,394,214,393,214,393,214,392,214,391,214,391,214,390,215,390,215,389,215,388,216,388,216,388,216,387,217,387,218,387,218,386,219,386,219,386,220,386,221,386,221,386,222,386,222,387,223,387,224,387,224,388,224,388,225,388,225,389,225,390,226,390,226,391,226,391,226,392",
                  alt: "thestrip",
                  onClick: () => {
                    navigate("/bellagio");
                  }
                }
              ]);
            }
          }
        ]);
      }
    }
  ]);

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabClick = (tabName) => {
    console.log(`You clicked the ${tabName} tab.`);
    // Here you might adjust this logic based on your application's needs
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        centered
      >
        <Tab label="Home" onClick={() => handleTabClick('Home')} />
        <Tab label="History" onClick={() => handleTabClick('History')} />
        <Tab label="Definitions" onClick={() => handleTabClick('Definitions')} />
        <Tab label="Tips" onClick={() => handleTabClick('Tips')} />
        <Tab label="Blog" onClick={() => handleTabClick('Blog')} />
        <Tab label="Merch" onClick={() => handleTabClick('Merch')} />
        <Tab label="Maps" onClick={() => handleTabClick('Maps')} />
      </Tabs>
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Map of USA
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          {mapImage ? (
            <img src={mapImage} alt="Map" useMap={`#${areas[0].name}`} />
          ) : (
            <p>Map image not found</p>
          )}
          <map name={areas[0].name}>
            <area shape="poly" coords={areas[0].coords} alt={areas[0].alt} onClick={areas[0].onClick} />
          </map>
        </Box>
      </Container>
    </Box>
  );
}
 

export default YourComponent;

