// Import necessary dependencies and initialize state as usual
import React, { useRef, useEffect } from 'react';
import slotImage from '../pics/slot-machine icon.png';
import { parseVertices, calculateCentroid } from '../utils/mapUtils';
import Tooltip from './Tooltip';
import { updateSlotLocation } from '../api';

const MultiMapArea = ({
  mapImage,
  slots,
  setSlots,
  submaps,
  subsections,
  moveSlot,
  interactionDisabled,
  slotCounts,
  handleAreaClick,
  showTooltip,
  setShowTooltip,
  tooltipPosition,
  setTooltipPosition,
  currentSlot,
  setCurrentSlot,
  tooltipDimensions,
  setTooltipDimensions,
  slotSize,
  setSlotSize,
  updateSlotLocation,
  deleteSlot,
  addSlot,
}) => {
  const mapContainerRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const tempTooltip = document.createElement('div');
    tempTooltip.className = 'tooltip';
    tempTooltip.style.visibility = 'hidden';
    document.body.appendChild(tempTooltip);
    const { offsetWidth, offsetHeight } = tempTooltip;
    setTooltipDimensions({ width: offsetWidth, height: offsetHeight });
    document.body.removeChild(tempTooltip);
  }, [setTooltipDimensions]);

  const handleShowSlotTooltip = (index, event) => {
    const slot = slots[index];
    setCurrentSlot(slot);

    const initialTooltipDimensions = { width: 359, height: 167 };
    const initialTooltipX = slot.x + (slotSize.width / 2) - (initialTooltipDimensions.width / 2);
    const initialTooltipY = slot.y - initialTooltipDimensions.height - 10;

    setTooltipPosition({ x: initialTooltipX, y: initialTooltipY });
    setShowTooltip(true);

    requestAnimationFrame(() => {
      if (tooltipRef.current) {
        const tooltipElement = tooltipRef.current;
        const tooltipWidth = tooltipElement.offsetWidth;
        const tooltipHeight = tooltipElement.offsetHeight;

        setTooltipDimensions({ width: tooltipWidth, height: tooltipHeight });
        const updatedTooltipX = slot.x + (slotSize.width / 2) - (tooltipWidth / 2);
        const updatedTooltipY = slot.y - tooltipHeight - 10;

        setTooltipPosition({ x: updatedTooltipX, y: updatedTooltipY });
      }
    });
  };

  const onDragStart = (e) => {
    const index = e.target.dataset.index;
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const index = e.dataTransfer.getData("index");
    const slot = slots[index];
    const mapRect = e.currentTarget.getBoundingClientRect();
    const newX = e.clientX - mapRect.left;
    const newY = e.clientY - mapRect.top;

    moveSlot(index, newX - slot.x, newY - slot.y);
  };

  const onDrag = (e) => {
    const index = e.target.dataset.index;
    const slot = slots[index];
    const mapRect = e.currentTarget.getBoundingClientRect();
    const newX = e.clientX - mapRect.left;
    const newY = e.clientY - mapRect.top;

    moveSlot(index, newX - slot.x, newY - slot.y);
  };

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setSlotSize({ width: naturalWidth, height: naturalHeight });
  };

  const saveSlotLocation = async (slotData) => {
    if (slotData) {
      try {
        if (!slotData.location_id) {
          console.error('Missing location_id for slotData:', slotData);
          return;
        }
        const responseData = await updateSlotLocation(slotData.location_id, slotData);
        const updatedSlot = { ...slotData, slot_id: responseData.slot_id };
        const updatedSlots = slots.map(slot => slot.location_id === slotData.location_id ? updatedSlot : slot);
        setSlots(updatedSlots);
        setShowTooltip(false);
      } catch (error) {
        console.error('Error saving slot location:', error);
      }
    }
  };

  return (
    <div className="map-container" onDragOver={onDragOver} onDrop={onDrop} style={{ position: 'relative', width: '100%', height: 'auto' }} ref={mapContainerRef}>
      <img src={mapImage} alt="Map" useMap="#map" />
      {slots.map((slot, index) => {
        const slotImg = slot.path ? slot.path : slotImage; // Use custom path if available, otherwise default
        return (
          <React.Fragment key={slot.location_id}>
            <img
              src={slotImg}
              alt="Slot"
              draggable="true"
              onDragStart={onDragStart}
              onDrag={onDrag}
              onClick={(event) => handleShowSlotTooltip(index, event)}
              onLoad={handleImageLoad}
              style={{ position: 'absolute', left: `${slot.x}px`, top: `${slot.y}px`, cursor: 'pointer' }}
              data-index={index}
            />
            {slot.name && (
              <div className="slot-name" style={{ left: `${slot.x + slotSize.width / 2 - (slot.name.length * 3)}px`, top: `${slot.y + slotSize.height}px` }}>
                {slot.name}
              </div>
            )}
          </React.Fragment>
        );
      })}
      {!interactionDisabled && subsections.map((section, index) => {
        const vertices = parseVertices(section.vertices);
        const centroid = calculateCentroid(vertices);
        return (
          <div key={index} style={{
            position: 'absolute',
            left: `${centroid.x}px`,
            top: `${centroid.y}px`,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1rem',
            pointerEvents: 'none',
            transform: 'translate(-50%, -50%)',
      textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black'
          }}>
            {slotCounts[index + 1] || 0}
          </div>
        );
      })}
      <map name="map">
        {submaps.map((section, index) => (
          <area key={index} shape="poly" coords={section.vertices}
            alt={`Section ${index + 1}`} onClick={() => handleAreaClick(index, false)} />
        ))}
        {subsections.map((subsection, index) => (
          <area key={index} shape="poly" coords={subsection.vertices}
            alt={`Subsection ${index + 1}`} onClick={() => handleAreaClick(index, true)} />
        ))}
      </map>
      {showTooltip && currentSlot && (
        <Tooltip
          slot={currentSlot}
          tooltipPosition={tooltipPosition}
          handleSlotChange={(e) => {
            const { name, value } = e.target;
            setCurrentSlot({ ...currentSlot, [name]: value });
          }}
          saveSlot={saveSlotLocation}
          deleteSlot={async (slotId) => {
            try {
              const responseData = await deleteSlot(slotId);
              const updatedSlots = slots.filter(g => g.slot_id !== slotId);
              setSlots(updatedSlots);
              setShowTooltip(false);
            } catch (error) {
              console.error('Error deleting slot:', error);
            }
          }}
          ref={tooltipRef}
        />
      )}
    </div>
  );
};

export default MultiMapArea;

