import React, { useState, useEffect, useRef } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MultiMapNavigation from './MultiMapNavigation';
import MultiMapArea from './MultiMapArea';
import { fetchSlotCounts, fetchSlots, updateSlotLocation, addSlot as addSlotAPI, deleteSlot, fetchSubmapId } from '../api';
import './InteractiveMap.css';

function InteractiveMultiMap({ mapName, mainImage, floors }) {
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);

  const [slots, setSlots] = useState([]);
  const [mapImage, setMapImage] = useState(mainImage);
  const [interactionDisabled, setInteractionDisabled] = useState(false);
  const [slotCounts, setSlotCounts] = useState({});
  const [currentSubmapId, setCurrentSubmapId] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem('username') || 'Guest');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [currentSlot, setCurrentSlot] = useState(null);
  const [tooltipDimensions, setTooltipDimensions] = useState({ width: 0, height: 0 });
  const [slotSize, setSlotSize] = useState({ width: 0, height: 0 });
  const [currentFloor, setCurrentFloor] = useState(Object.keys(floors)[0]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const counts = await fetchSlotCounts();
        setSlotCounts(counts);
      } catch (error) {
        console.error('Failed to fetch slot counts:', error);
      }
    };
    fetchCounts();
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setUsername(localStorage.getItem('username') || 'Guest');
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleFloorChange = (floorKey) => {
    setCurrentFloor(floorKey);
    resetMap(floorKey);
  };
 const handleAreaClick = async (sectionIndex, isSubsection = false) => {
  if (!interactionDisabled) {
    const floor = floors[currentFloor];
    const section = isSubsection ? floor.subsections[sectionIndex] : floor.areas[sectionIndex];

    if (section) {
      if (!isSubsection && section.img !== floor.mainImage) {
        // This is a map transition point
        handleFloorChange(Object.keys(floors).find(key => floors[key].mainImage === section.img));
        return;
      }

      // This is a subsection
      setMapImage(section.img);
      setInteractionDisabled(true);
      try {
        const submapId = await fetchSubmapId(section.img);
        setCurrentSubmapId(submapId);
        const fetchedSlots = await fetchSlots(submapId);
        setSlots(fetchedSlots);
      } catch (error) {
        alert('Failed to fetch slots from the selected subsection.');
      }
    } else {
      console.error('Section not found:', sectionIndex, isSubsection);
    }
  }
};

  const resetMap = (floorKey) => {
    const floor = floors[floorKey];
    if (floor) {
      setMapImage(floor.mainImage);
      setInteractionDisabled(false);
      setSlots([]);
      setCurrentSubmapId(null);
    } else {
      console.error('Floor not found:', floorKey);
    }
  };

  const moveSlot = (index, dx, dy) => {
    const newSlots = [...slots];
    newSlots[index] = { ...newSlots[index], x: newSlots[index].x + dx, y: newSlots[index].y + dy };
    setSlots(newSlots);

    if (showTooltip && currentSlot && currentSlot.location_id === newSlots[index].location_id) {
      const updatedTooltipX = newSlots[index].x + slotSize.width / 2 - tooltipDimensions.width / 2;
      const updatedTooltipY = newSlots[index].y - tooltipDimensions.height - 10;
      setTooltipPosition({ x: updatedTooltipX, y: updatedTooltipY });
    }

    setCurrentSlot(prevSlot => ({
      ...prevSlot,
      x_coordinate: newSlots[index].x,
      y_coordinate: newSlots[index].y,
    }));
  };

  const createLocalSlot = (x, y, submapId) => {
    const newSlot = {
      id: slots.length + 1,
      x,
      y,
      name: 'New Slot',
      description: '',
      cost: 0,
      software: '',
      path: '',
      submap_id: submapId,
      date: new Date().toISOString().split('T')[0],
    };
    setSlots([...slots, newSlot]);
  };

  const handleAddSlotClick = async () => {
    if (mapContainerRef.current) {
      const rect = mapContainerRef.current.getBoundingClientRect();
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;

      const newSlot = {
        x: centerX,
        y: centerY,
        name: 'New Slot',
        description: '',
        cost: 0,
        path: '',
        submap_id: currentSubmapId,
        date: new Date().toISOString().split('T')[0],
        software: ''
      };

      try {
        const savedSlot = await addSlotAPI(newSlot);
        setSlots([...slots, { ...newSlot, slot_id: savedSlot.slot_id, location_id: savedSlot.location_id }]);
      } catch (error) {
        console.error('Failed to add slot:', error);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MultiMapNavigation
        mapName={mapName}
        username={username}
        handleFloorChange={handleFloorChange}
        handleLogout={handleLogout}
        resetMap={() => resetMap(currentFloor)}
        floors={floors}
        currentFloor={currentFloor}
      />
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }} ref={mapContainerRef}>
        <h1>Interactive Map of {mapName}</h1>
        {interactionDisabled && (
          <button className="add-slot-button" onClick={handleAddSlotClick}>Add Slot</button>
        )}
        <MultiMapArea
          mapImage={mapImage}
          slots={slots}
          setSlots={setSlots}
          submaps={floors[currentFloor]?.areas || []}
          subsections={floors[currentFloor]?.subsections || []}
          moveSlot={moveSlot}
          interactionDisabled={interactionDisabled}
          slotCounts={slotCounts}
          handleAreaClick={handleAreaClick}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          tooltipPosition={tooltipPosition}
          setTooltipPosition={setTooltipPosition}
          currentSlot={currentSlot}
          setCurrentSlot={setCurrentSlot}
          tooltipDimensions={tooltipDimensions}
          setTooltipDimensions={setTooltipDimensions}
          slotSize={slotSize}
          setSlotSize={setSlotSize}
          updateSlotLocation={updateSlotLocation}
          deleteSlot={deleteSlot}
          addSlot={createLocalSlot}
        />
      </Container>
    </Box>
  );
}

export default InteractiveMultiMap;


