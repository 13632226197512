// src/api.js

import axios from 'axios';

const BASE_URL = 'http://165.232.129.161:5000';

export const fetchSlotNames = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/slots/names`);
    return response.data; // Assuming the API returns an array of slot names
  } catch (error) {
    console.error('Failed to fetch slot names:', error);
    throw error;
  }
};



export const fetchSlotCounts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/slots/count`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch slot counts:', error);
    throw error;
  }
};


export const fetchSlotByName = async (name) => {
  try {
    const response = await axios.get(`${BASE_URL}/slots/name/${name}`);
    if (response.status === 200 && response.data) {
      return response.data;
    }
    return null; // Return null if slot is not found
  } catch (error) {
    console.error('Failed to fetch slot by name:', error);
    return null; // Return null on error
  }
};
export const fetchSlots = async (submapId) => {
  try {
    const response = await axios.get(`${BASE_URL}/slots/${submapId}`);
    console.log('Fetched slots:', response.data); // Log the fetched slots
    return response.data.map(slot => ({
      ...slot,
      id: slot.slot_id,
      location_id: slot.location_id,  // Ensure location_id is included
      x: slot.x_coordinate,
      y: slot.y_coordinate,
      name: slot.name,
      description: slot.description,
      cost: slot.cost,
      software: slot.software,
      path: slot.path,
      submap_id: slot.submap_id,
      date: slot.date,
    }));
  } catch (error) {
    console.error('Failed to fetch slots:', error);
    throw error;
  }
};

export const updateSlotLocation = async (locationId, slotData) => {
  const apiURL = `${BASE_URL}/slotlocations/${locationId}`;
  try {
    const response = await axios.put(apiURL, slotData);
    return response.data;
  } catch (error) {
    console.error('Error updating slot location:', error);
    throw error;
  }
};


export const saveSlot = async (slot) => {
  const apiMethod = slot.slot_id ? 'put' : 'post';
  const apiURL = slot.slot_id
    ? `${BASE_URL}/slots/${slot.slot_id}`
    : `${BASE_URL}/slots`;

  try {
    const response = await axios[apiMethod](apiURL, {
      name: slot.name,
      description: slot.description,
      grandprize: slot.grandprize,
      grandprize_chance: slot.grandprize_chance,
      instructions: slot.instructions,
      cost: slot.cost,
      path: slot.path,
      submap_id: slot.submap_id,
      x_coordinate: slot.x,
      y_coordinate: slot.y,
      date: slot.date,
      software: slot.software,
    });
    return response.data;
  } catch (error) {
    console.error('Error saving slot:', error);
    throw error;
  }
};

export const deleteSlot = async (slotId) => {
  const apiURL = `${BASE_URL}/slots/${slotId}`;
  try {
    const response = await axios.delete(apiURL);
    return response.data;
  } catch (error) {
    console.error('Error deleting slot:', error);
    throw error;
  }
};

// Add the addSlot function
export const addSlot = async (slotData) => {
  try {
    const response = await axios.post(`${BASE_URL}/slots`, slotData);
    return response.data;
  } catch (error) {
    console.error('Error adding slot:', error);
    throw error;
  }
};


export const fetchSubmapId = async (path) => {
  try {
    const response = await axios.post(`${BASE_URL}/submap-id`, { path });
    return response.data.submap_id;
  } catch (error) {
    console.error('Failed to fetch submap ID:', error);
    throw error;
  }
};
