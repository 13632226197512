import React from 'react';
import { AppBar, Tabs, Tab, Typography, Toolbar, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const MultiMapNavigation = ({ mapName, username, handleFloorChange, handleLogout, resetMap, floors, currentFloor }) => {
  const floorKeys = Object.keys(floors);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {mapName} Interactive Map
        </Typography>
        <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
          {username ? `Hello, ${username}` : 'Not logged in'}
        </Typography>
        <Button color="inherit" onClick={handleLogout}>Logout</Button>
      </Toolbar>
      <Tabs indicatorColor="secondary" textColor="inherit" centered>
        <Tab label="Home" onClick={() => handleFloorChange(floorKeys[0])} />
        <FormControl sx={{ minWidth: 120, marginTop: '8px' }}>
          <InputLabel id="floor-select-label">Floor</InputLabel>
          <Select
            labelId="floor-select-label"
            id="floor-select"
            value={currentFloor}
            label="Floor"
            onChange={(e) => handleFloorChange(e.target.value)}
          >
            {floorKeys.map((floorKey) => (
              <MenuItem key={floorKey} value={floorKey}>
                {floorKey}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tab label={`${mapName} Map`} onClick={resetMap} />
      </Tabs>
    </AppBar>
  );
};

export default MultiMapNavigation;

