// App.js

import React from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Bellagio from './Bellagio';
import PlazaMulti from './PlazaMulti';
import YourComponent from './test'; // Ensure the import name matches the component name
import { UserProvider } from './UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/bellagio" element={<Bellagio />} />
          <Route path="/plaza" element={<PlazaMulti />} />
          <Route path="/test" element={<YourComponent />} /> {/* Corrected to use YourComponent */}
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;

